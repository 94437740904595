import { Version } from './version.model';

export const version: Version = {
  build: {
    time: '2024-09-17T12:07:47+0000',
  },
  git: {
    branchName: 'master',
    commitHash: '2055097d10fdf3077e218430a04d7b3ef4e8b15f',
  },
};
